@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@font-face {
    font-family: 'Sweet Sans Pro Heavy';
    src: url('../assets/fonts/SweetSansProHeavy.woff2') format('woff2'),
        url('../assets/fonts/SweetSansProHeavy.woff') format('woff');
}
@font-face {
    font-family: 'Sweet Sans Pro Regular';
    src: url('../assets/fonts/SweetSansProRegular.woff2') format('woff2'),
        url('../assets/fonts/SweetSansProRegular.woff') format('woff');
}
@font-face {
    font-family: 'Sweet Sans Pro Thin';
    src: url('../assets/fonts/SweetSansProThin.woff2') format('woff2'),
        url('../assets/fonts/SweetSansProThin.woff') format('woff');
}

.games {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 185px);

    h1 {
        font-family: 'Sweet Sans Pro Heavy', sans-serif;
        font-size: 48px;
        margin-top: 64px;
        margin-bottom: 0;
        text-transform: uppercase;

        @media (max-width: 500px) {
            font-size: 36px;
            margin-top: 36px;
        }
    }
    
    h2 {
        font-family: 'Sweet Sans Pro Thin', sans-serif;
        margin-top: 0.5em;
        font-size: 24px;
        font-weight: 100;
        color: goldenrod;

        @media (max-width: 500px) {
            font-size: 20px;
        }
    }
}

.games-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 400px);
    gap: 15px 35px;
    width: 95%;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 64px;

    @media (max-width: 500px) {
        grid-template-columns: repeat(auto-fit, 90%)
    }
}

.game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-bottom: 1em;
    // border-radius: 20px;
    overflow: hidden;
    background: #222222;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    height: 300px;
    border: 2px solid transparent;

    &:hover {
        background: #333;
        border: 2px solid goldenrod;
        box-shadow: 0px 0px 15px rgba(218, 165, 32, 0.3);
        transition: 0.5s ease;
    }

    img {
        width: 150px; 
        height: 150px; 
        margin-top: -10px;
        margin-bottom: -10px;
    }
}

.column-left > * {
    margin-left: 15px;
}

.column-right > * {
    margin-right: 15px;
}

.probability {
    font-size: 36px; 
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
}

.record {
    color: #666;
    margin-top: 10px;
}

.game-head {
    grid-column: span 3;
    font-size: 1em;
}

.matchup {
    color: #BBB;
    margin-bottom: 10px;

    @media (max-width: 500px) {
        font-size: 15px;
    }
}

.time {
    color: #777;
    margin-top: 10px;
    margin-bottom: 0;

    @media (max-width: 500px) {
        font-size: 13px;
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding-top: 20%;
}

.day-navigator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
        background-color: #444;
        color: #fff;
        border: none;
        padding: 8px 12px;
        margin: 0 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:disabled {
            background-color: #222;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            background-color: #555;
        }
    }

    .date-display {
        margin: 0 10px;
        font-size: 18px;
        color: #fff;
    }
}

.no-games-message {
    font-size: 24px;
    color: #bbb;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 500px;
        height: auto;
        margin-top: 20px;
    }
}