body {
    padding: 60px 0 0 0;
    margin: 0;
    background-color: #111111;
    color: #fff;
}

ul {
    margin: 0;
    padding: 0;
}