.player {
    color: white;
    background-color: #111;
    padding: 20px;
    margin: 10px auto;
  
    .header-bar {
      height: 200px;
      width: calc(100% + 40px);
      margin: -20px -20px;
      background-color: #222;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      .player-headshot {
        height: 200px;
        width: auto;
      }

      .player-headshot-name {
        display: flex;
        align-items: center;
      }

      .player-name {
        flex-grow: 0;
        display: inline-block;
        text-align: left;
        font-size: 42px;
        font-family: 'Sweet Sans Pro Heavy', sans-serif;
        color: white;
        margin-left: clamp(10px, 1.8vw, 25px);

        .player-details {
          font-size: 24px;
          font-family: 'Sweet Sans Pro Regular', sans-serif;
          color: #aaa;
          margin-top: 10px;
        }
      }

      .player-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 20px 10px 0px 10px;

        .player-age,
        .player-position {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-family: 'Sweet Sans Pro Regular', sans-serif;
          color: #aaa;
          margin: 0 20px;

          .label {
            font-size: 14px;
            color: #777;
            margin-right: 10px;
          }
        }
      }

      @media (max-width: 1125px) {
        .player-info {
          flex-direction: column;
          align-items: flex-start;
          margin-right: 0px;
        }
      }

      .team-logo {
        height: 150px;
        width: 150px;
        margin: 0 35px;
      }
    }

    .content {
      padding: 20px;
      background-color: #111;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .left-content,
      .right-content {
        width: 48%;
        display: flex;
        flex-direction: column;
      }

      .projections,
      .probabilities,
      .chart-container {
        flex-grow: 1;
      }

      .projections {
        border: 1px solid #444;
        padding: 20px 15px 15px 15px;
        margin-top: 20px;
        position: relative;

        .header {
          position: absolute;
          top: -10px;
          left: 20px;
          background-color: #111;
          padding: 0 10px;
          font-size: 18px;
          font-family: 'Sweet Sans Pro Regular', sans-serif;
          color: white;
          width: auto;
          z-index: 1;
        }

        .projections-row {
          display: flex;
          justify-content: space-around;
          margin-top: 10px;
        }

        .awards-row {
          display: flex;
          justify-content: space-around;
          margin-top: 10px;
        }

        .projection-item {
          text-align: center;
          font-size: clamp(2px, 2.2vw, 24px);
          font-family: 'Sweet Sans Pro Regular', sans-serif;
          color: #aaa;
          background-color: #222;
          padding: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          width: 20%;
          margin: 0 10px;
          min-width: 10%;

          .label {
            font-size: clamp(2px, 1.2vw, 14px);
            color: #777;
            margin-top: 5px;
          }
        }

        .award-item {
          text-align: center;
          font-size: clamp(2px, 2.2vw, 24px);
          font-family: 'Sweet Sans Pro Regular', sans-serif;
          color: #aaa;
          background-color: #222;
          padding: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          width: 50%;
          margin: 10px 10px;
          min-width: 100px;

          .label {
            font-size: clamp(2px, 1.2vw, 14px);
            color: #777;
            margin-top: 5px;
          }
        }
      }

      .probabilities {
        margin-top: 40px;
        border: 1px solid #444;
        padding: 20px 15px 15px 15px;
        background-color: #111;
        position: relative;

        .header {
          position: absolute;
          top: -10px;
          left: 20px;
          background-color: #111;
          padding: 0 10px;
          font-size: 18px;
          font-family: 'Sweet Sans Pro Regular', sans-serif;
          color: white;
          width: auto;
          z-index: 1;
        }

        .tabs {
          display: flex;
          justify-content: space-around;

          .tab {
            font-size: 18px;
            font-family: 'Sweet Sans Pro Regular', sans-serif;
            color: #aaa;
            padding: 5px;
            margin-top: 10px;
            cursor: pointer;
            &.active {
              color: white;
              border-bottom: 2px solid #daa520;
            }
          }
        }

        .probability-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .benchmarks-row {
            display: flex;
            justify-content: space-around;
            margin: 10px 0;
            width: 100%;
          }

          .pi-row {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 20px;

            .pi-item {
              text-align: center;
              font-size: clamp(2px, 2.2vw, 24px);
              font-family: 'Sweet Sans Pro Regular', sans-serif;
              color: #aaa;
              background-color: #222;
              padding: 10px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
              width: 50%;
              margin: 10px 10px;
              min-width: 100px;

              .label {
                font-size: clamp(2px, 1.2vw, 14px);
                color: #777;
                margin-top: 5px;
              }
            }
          }

          .benchmark-item {
            text-align: center;
            font-size: clamp(2px, 1.4vw, 24px);
            font-family: 'Sweet Sans Pro Regular', sans-serif;
            color: #aaa;
            background-color: #222;
            padding: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            width: 75px;
            width: 20%;
            margin: 0 10px;
            min-width: 10%;

            .label {
              font-size: clamp(2px, 1.2vw, 14px);
              color: #777;
              margin-top: 5px;
            }
          }
        }
      }

      .chart-container {
        border: 1px solid #444;
        padding: 20px 15px 15px 15px;
        margin-top: 20px;
        background-color: #111;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
          position: absolute;
          top: -10px;
          left: 20px;
          background-color: #111;
          padding: 0 10px;
          font-size: 18px;
          font-family: 'Sweet Sans Pro Regular', sans-serif;
          color: white;
          width: auto;
          z-index: 1;
        }
      }

      @media (max-width: 1475px) {
        .probabilities .probability-content .benchmark-item .label {
          white-space: pre-wrap;
          text-align: center;
        }
      }
    }

    h1 {
      margin-bottom: 10px;
    }
  
    p {
      margin: 5px 0;
      strong {
        color: #daa520;
      }
    }
  }

.spacer {
  height: max(0px, calc(100vh - 1100px));
}