@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@font-face {
    font-family: 'Sweet Sans Pro Heavy';
    src: url('../assets/fonts/SweetSansProHeavy.woff2') format('woff2'),
        url('../assets/fonts/SweetSansProHeavy.woff') format('woff');
}
@font-face {
    font-family: 'Sweet Sans Pro Regular';
    src: url('../assets/fonts/SweetSansProRegular.woff2') format('woff2'),
        url('../assets/fonts/SweetSansProRegular.woff') format('woff');
}
@font-face {
    font-family: 'Sweet Sans Pro Thin';
    src: url('../assets/fonts/SweetSansProThin.woff2') format('woff2'),
        url('../assets/fonts/SweetSansProThin.woff') format('woff');
}

@import './PlayerLargeScreen.scss';

@media (max-width: 1000px) {
    @import './PlayerSmallScreen.scss';
}