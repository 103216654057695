@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.header {
    position: relative;
    font-family: 'Michroma';
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: rgba(34, 34, 34, 0.75);
    backdrop-filter: blur(10px);
    z-index: 1000;

    .header-name {
        font-size: 10px;
        margin: 0 20px;
        display: flex;
        align-items: center;

        img {
            // margin-right: 10px;
            width: 50px;
        }

        h1 {
            margin: 0 0 0 15px;
            font-size: 21px;
            letter-spacing: 2px;
            font-weight: 400;
        }
    }

    nav {
        ul {
            display: flex;
            gap: 10px;
            list-style: none;
            margin: 0;

            li {
                margin: 0 10px 0 10px;

                a {
                    color: #fff;
                    font-size: 12px;
                    text-decoration: none;
                    position: relative;

                    &:hover {
                        color: #BBB;
                        transition: 0.25s ease;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        height: 1px;
                        width: 0;
                        background: #AAA;
                        transition: width 0.5s;
                    }

                    &:hover::after {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 999px) {
        .header-name {
            font-size: 10px;
            margin: 0 1%;
            display: flex;
            align-items: center;
    
            img {
                // width: 12%;
                width: 40px;
            }
    
            h1 {
                margin: 0 0 0 15px;
                letter-spacing: 1.5px;
                font-weight: 400;
                font-size: 16px;
            }
        }

        nav {
            button {
                background: none;
                border: none;
                color: #fff;
                font-size: 12px;
            }

            ul {
                display: none;
                flex-direction: column;
                gap: 10px;
                list-style: none;
                margin: 0;
                position: absolute;
                top: 100%;
                right: 10px;
                background: #333;
                padding: 10px;
                border-radius: 5px;
                text-align: right;

                li {
                    a {
                        color: #fff;
                        font-size: 12px;
                        text-decoration: none;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            height: 1px;
                            width: 0;
                            background: #AAA;
                            transition: width 0.5s;
                        }

                        &:hover::after {
                            width: 100%;
                        }
                    }
                }
            }

            button[aria-expanded='true'] + ul {
                display: flex;
            }
        }
    }
}